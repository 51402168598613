/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  box-sizing: border-box;
}

.app-container {
  text-align: center;
  color: #333;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  padding: 10px 20px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header .logo h1 {
  color: white;
  margin: 0;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav ul li a {
  color: white;
  text-decoration: none;
}

.nav ul li a:hover {
  text-decoration: underline;
}

/* Hero Section */
.hero {
  background-image: linear-gradient(to right, #007bff, #00c6ff);
  color: white;
  padding: 100px 20px;
  text-align: center;
}

.hero h1 {
  font-size: 48px;
  margin: 0;
}

.hero p {
  font-size: 20px;
  margin: 20px 0;
}

.cta-button {
  background-color: white;
  color: #007bff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.cta-button:hover {
  background-color: #f0f0f0;
}

/* About Section */
.about {
  padding: 50px 20px;
  background-color: #f9f9f9;
  color: #333;
}

/* Blog Section */
.blog {
  padding: 50px 20px;
}

.blog-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.blog-post {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-post h3 {
  color: #007bff;
  margin: 0 0 10px;
}

.blog-post p {
  color: #555;
}

.blog-post a {
  color: #007bff;
  text-decoration: none;
}

.blog-post a:hover {
  text-decoration: underline;
}

/* Footer */
footer {
  padding: 20px;
  background-color: #007bff;
  color: white;
  text-align: center;
}
.appointment {
  margin: 40px 0;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.appointment h2 {
  text-align: center;
  color: #007bff;
  margin-bottom: 20px;
}
