.header {
  background-color: #333;
  padding: 15px 20px; /* Adjust padding for a better look */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand a {
  color: white;
  font-size: 1.5rem;
  text-decoration: none;
}

.navbar-links {
  display: flex;
  list-style: none;
  gap: 20px; /* Space between links */
}

.navbar-links li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.navbar-links li a:hover {
  text-decoration: underline;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links {
    flex-direction: column;
    gap: 10px; /* Reduce gap for mobile */
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
  }

  .navbar-links li {
    margin: 5px 0;
  }
}
