.home-container {
    text-align: center;
  }
  
  .hero {
    padding: 50px 20px;
    background: linear-gradient(135deg, #007bff, #00bfff);
    color: white;
  }
  
  .hero-content h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .hero-content p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .cta-button {
    padding: 10px 20px;
    background-color: #fff;
    color: #007bff;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #f1f1f1;
  }
  
  .ram-info {
    background-color: #f9f9f9;
    padding: 40px 20px;
    text-align: center;
  }
  
  .ram-content {
    max-width: 600px;
    margin: 0 auto;
  }
  
  .ram-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
  
  .ram-info h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .ram-info p {
    font-size: 1.1em;
    color: #666;
    margin-bottom: 20px;
  }
  
  .booking-button {
    padding: 10px 25px;
    background-color: #007bff;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .booking-button:hover {
    background-color: #0056b3;
  }
  .ebook-download {
    text-align: center;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 600px;
  }
  
  .ebook-download h2 {
    font-size: 1.8rem;
    color: #333;
  }
  
  .ebook-download p {
    font-size: 1rem;
    color: #555;
    margin: 10px 0;
  }
  
  .download-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .download-button:hover {
    background-color: #0056b3;
  }
  