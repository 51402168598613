.blog-page {
    margin: 20px auto;
    max-width: 800px;
    padding: 20px;
  }
  
  .blog-page h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #007bff;
  }
  
  .blog-post {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .blog-post h2 {
    margin-bottom: 10px;
    color: #007bff;
  }
  
  .blog-post p {
    color: #555;
    line-height: 1.6;
  }
  
  .read-more-link {
    display: inline-block;
    margin-top: 10px;
    color: #007bff;
    font-weight: bold;
  }
  
  .read-more-link:hover {
    text-decoration: underline;
  }
  